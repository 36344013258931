<template>
	<AddEditForm :headerTitle="$t('pages.product.addUnitConversion')" :backTitle="$t('pages.product.backToUnitPage')" :onGoBack="onGoBack" :onSave="onSave" :onSaveContinue="onSaveContinue" :showDelete="false">
        <template #content>
            <Accordion :headerTitle="$t('common.generalInfo')">
                <template v-slot:body>
                    <CForm>

                        <div role="group" class="form-group form-row">
                            <label class="col-sm-3 col-form-label">
                                {{ $t('pages.product.fromUnit') }}
                                <small class="requiredInput">(*)</small>
                            </label>
                            <div class="col-sm-9 input-group">
                                <select v-model="values.fromUnitId" class="form-control">
                                    <option selected="selected" value="0">{{ $t('pages.product.selectUnit') }}</option>
                                    <option v-for="(item) in allUnits" v-bind:key="item.id" v-bind:value="item.id">
                                        {{item.name}}
                                    </option>
                                </select>

                                <p class="errors" v-if="!!errors.fromUnitId">{{errors.fromUnitId}}</p>
                            </div>
                        </div>

                        <div role="group" class="form-group form-row">
                            <label class="col-sm-3 col-form-label">
                                {{ $t('pages.product.toUnit') }}
                                <small class="requiredInput">(*)</small>
                            </label>
                            <div class="col-sm-9 input-group">
                                <select v-model="values.toUnitId" class="form-control">
                                    <option selected="selected" value="0">{{ $t('pages.product.selectUnit') }}</option>
                                    <option v-for="(item) in allUnits" v-bind:key="item.id" v-bind:value="item.id">
                                        {{item.name}}
                                    </option>
                                </select>

                                <p class="errors" v-if="!!errors.toUnitId">{{errors.toUnitId}}</p>
                            </div>
                        </div>

                        <CInput :label="$t('pages.product.points')" :placeholder="$t('pages.product.conversionValue')"
                                v-model="values.ratio" horizontal>
                        </CInput>
                        <p class="errors" v-if="!!errors.ratio">{{errors.ratio}}</p>
                    </CForm>
                </template>
            </Accordion>


            <Confirmation ref="confirmation"></Confirmation>
                      
        </template>

	</AddEditForm>
</template>




<script>
    import { mapGetters } from 'vuex'
    import Accordion from '@/components/Accordion.vue'
    import AddEditForm from '@/components/AddEditForm.vue'
    import Confirmation from '@/components/Confirmation.vue'
    import _ from 'lodash'
    import * as yup from 'yup'

    const formSchema = yup.object().shape({
        fromUnitId: yup.number().min(1, 'Chọn đơn vị'),
        toUnitId: yup.number().min(1, 'Chọn đơn vị'),
        ratio: yup.number().min(1, 'Nhập tỷ lệ')
    })

    export default {
        name: 'UnitConversionAdd',
		data() {
            return {
                values: {
                    fromUnitId: 0,
                    toUnitId: 0,
                    ratio: 0,
                },
                submitting: false,
                errors: {
                    fromUnitId: "",
                    toUnitId: "",
                    ratio: "",
                }
			}
		},	
        components: { AddEditForm, Accordion, Confirmation },
        computed: {
            ...mapGetters('unit', ['allUnits']),
        },
        methods: {
             validator(val) {
                return val ? val.length > 0 : false
            },

            validate(field) {
                formSchema.validateAt(field, this.values)
                    .then(() => this.errors[field] = '')
                    .catch((err) => {
                        this.errors[err.path] = err.message
                        console.log('validate error', err);
                    });
            },

            onGoBack() {
                this.$router.push('/units/conversions/list');
            },
            onSave() {                
                this.onAddConversion(false);
            },
            onSaveContinue() {
                this.onAddConversion(true);
            },
            onCancel() {               
                this.$router.push('/units/conversions/list');
            },

            async onAddConversion(bContinueEdit) {                
                console.log("Add Unit Conversion.......", this.values);
                try {
                    await formSchema.validate(this.values, { abortEarly: false });
                    this.errors = {};
                    
                    try {
                        this.submitting = true;
                        var result = await this.$store.dispatch('unit/addUnitConversion', {
                            fromUnitId: this.values.fromUnitId,
                            toUnitId: this.values.toUnitId,
                            ratio: this.values.ratio,
                        });
                        if (result != null && result.id > 0) {
                            if (bContinueEdit) {
                                this.$router.push('/units/conversions/edit/' + result.id);
                            }
                            else {
                                this.onGoBack();
                            }
                        }

                    }
                    catch {
                        this.submitting = false;
                    }
                }
                catch (err) {
                    this.errors = {};
                    _.forEach(err.inner, error => {
                        console.log("EACH ERROR", error)
                        this.errors[error.path] = error.message;
                    })
                }
			},
           
        },
        mounted() {
            this.$store.dispatch('unit/getAllUnits');
        },		
    }
</script>
